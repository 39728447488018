@use "styles/shared-variables" as *;
@use "../../../../node_modules/normalize.css/normalize.css";
@use "@edgetier/components/dist/spinner/spinner";
@use "styles/keyframes";
@use "breakpoint-slicer" as breakpoints with (
    $slices: $slicer-breakpoints,
);

html,
body {
    height: 100%;
    overflow: auto;

    font-size: 14px;

    @include breakpoints.from(tablet) {
        font-size: 16px;
    }
}

* {
    box-sizing: border-box;
}

#root {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $flat-wet-asphalt;
    height: 100%;
}
.survey-app-container {
    overflow-y: auto;
    display: flex;
    min-height: 100%;

    @include spinner.spinner();
}
