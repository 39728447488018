@use "styles/shared-variables" as *;
@use "breakpoint-slicer" as breakpoints;

.loading-survey {
    text-align: center;

    h1,
    h2,
    .spinner {
        @include pop--in;
    }
    h1 {
        animation-delay: 0.4;
    }
    h2 {
        animation-delay: 0.6s;
    }
    .spinner {
        margin: $spacing-x-large 0;
        animation-delay: 0.01s;
    }

    @include breakpoints.from(tablet) {
        max-width: $mobile;
    }

    .spinner {
        svg {
            height: 45px;
            width: 45px;
        }
    }
}
