$border-radius: 4px;

$font-size-small: 1.1rem;
$font-size-medium: 1.15rem;
$font-size-base: 1.3rem;
$font-size-large: 1.5rem;

$heading-line-height: 40px;
$line-height-medium: $font-size-medium * 1.3;
$line-height-base: $font-size-base * 1.3;
