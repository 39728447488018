@use "styles/shared-variables" as *;
@use "@edgetier/dynamic-form/styles" as *;
@use "styles/library/typography";

.page.survey-page.page-has-error {
    justify-content: center;
}

.page.survey-page {
    justify-content: flex-start;
    padding: $spacing-xxx-large 0;

    $selected-colour: $flat-peter-river;
    $hover-colour: rgba($flat-peter-river, 0.2);

    .error-display {
        text-align: center;

        > * {
            margin: $spacing-base;
        }

        &__primary {
            font-size: typography.$font-size-large;
        }
        &__line-1 {
            font-size: typography.$font-size-base;
        }
    }

    form {
        counter-reset: form-fields;
        margin-top: $spacing-x-small;
        border-top: 2px solid $flat-clouds;
        max-width: 650px;
        color: $flat-wet-asphalt;
        padding: $spacing-x-small;

        @include from(tablet) {
            padding: $spacing-large;
        }

        // Fields
        .field {
            // Todo: Decide on optional field borders
            // border: 1px solid $flat-clouds;
            // background-color: white;
            // border-radius: $border-radius;
            // padding: $spacing-x-small;

            // Top level labels
            > label {
                padding: $spacing-base 0 $spacing-x-large;

                &::before {
                    content: counter(form-fields) ". ";
                    counter-increment: form-fields;
                }
            }
        }

        // Button groups¸
        .dynamic-form__buttons {
            &__button {
                padding: 0;

                + .dynamic-form__buttons__button {
                    padding-left: $spacing-xxx-large;
                }

                &:hover {
                    label {
                        background-color: $hover-colour;
                    }
                }

                label {
                    font-size: $font-size-base;
                }

                &--selected {
                    &:hover {
                        label {
                            background-color: $selected-colour;
                            border-color: $flat-belize-hole;
                        }
                    }
                    label {
                        background-color: $selected-colour;
                        border-color: $flat-belize-hole;
                        color: $flat-clouds;
                    }

                    input:focus + label {
                        border-color: $flat-asbestos;
                    }
                }
            }

            &__container {
                margin: unset;
            }

            // Radio buttons¸
            &[role="radiogroup"] {
                .dynamic-form__buttons__button {
                    padding: 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin: $spacing-x-small 0;
                }
                @include from(tablet) {
                    .dynamic-form__buttons__container {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    .dynamic-form__buttons__button {
                        width: unset;
                        min-width: 33%;
                        flex-shrink: 0;
                    }
                }
            }
        }

        // Drop down Select Fields
        .react-select {
            &__control {
                padding: $spacing-small;
            }

            &__option {
                padding: $spacing-x-large;

                &--is-focused {
                    background-color: $hover-colour;
                }
                &--is-selected {
                    background-color: $flat-emerald;
                }
            }
            &__menu {
                overflow: hidden;
            }
            &__menu-list {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        // NPS & rating items
        .dynamic-form__net-promoter-score__labels {
            font-size: typography.$font-size-small;
            padding: $spacing-xx-small 0;
        }

        .dynamic-form__rating__item {
            font-size: typography.$font-size-base;
            position: unset;

            label {
                line-height: typography.$font-size-medium;
            }

            &:first-of-type .dynamic-form__net-promoter-score__item {
                border-radius: 4px 0 0 4px;
            }

            &:last-of-type .dynamic-form__net-promoter-score__item {
                border-radius: 0 4px 4px 0;
            }

            &--selected {
                .dynamic-form__net-promoter-score__item {
                    font-size: typography.$font-size-large;
                    background-color: $flat-alizarin;
                }

                &:nth-of-type(n + 4) {
                    .dynamic-form__net-promoter-score__item {
                        background-color: $flat-orange;
                    }
                }

                &:nth-of-type(n + 8) {
                    .dynamic-form__net-promoter-score__item {
                        background-color: $flat-emerald;
                    }
                }
            }

            &--in-range {
                .dynamic-form__net-promoter-score__item {
                    background-color: rgba($flat-alizarin, 0.2);
                }

                &:nth-of-type(n + 4) {
                    .dynamic-form__net-promoter-score__item {
                        background-color: rgba($flat-orange, 0.2);
                    }
                }

                &:nth-of-type(n + 8) {
                    .dynamic-form__net-promoter-score__item {
                        background-color: rgba($flat-emerald, 0.2);
                    }
                }
            }
        }
    }
}
