@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-bounce {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    50% {
        opacity: 1;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pop {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes slide-left {
    from {
        opacity: 0;
        transform: translateX(-20%);
    }
}
